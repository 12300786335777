<template>
	<div class="p-login-all" v-if="show==1">
		<div class="displayall" @click="exitshow()">
		</div>
		<div class="p-loginbox" v-if="type==1">
			<div><span class="mr64">账号密码登录</span><span @click="exitshow()" class="loginfirst">×</span></div>
			<div class="p-login-input">
				<div class="p-login-span">手机号</div><input @input="getcontent" v-model="phone" type="number" class="p-login-input-text"></input>
			</div>
			<div class="p-login-line"></div>
			<div class="p-login-input">
				<div class="p-login-span">密码</div><input @input="getcontent" v-model="password" type="password" class="p-login-input-text"></input>
			</div>
			<div class="p-login-line"></div>
			<div class="p-login-other"> <span @click="choosetype(3)">其他登录方式 ＞</span></div>
			<div @click="login(1)" :class="['p-login-button',ispass==2?'p-login-button p-opcty':'']">确定</div>
			<div class="p-login-service">登录即同意<span @click="toxieyi('服务协议',3)">服务条款</span>和<span @click="toxieyi('隐私协议',item.id)">隐私政策</span></div>
		</div>
		<div class="p-loginbox" v-if="type==2">
			<div><span class="mr64">手机号注册</span><span @click="exitshow()" class="loginfirst">×</span></div>
			<div class="p-login-input">
				<div class="p-login-span">手机号</div><input @input="getcontent" v-model="phone" type="number" class="p-login-input-text"></input>
			</div>
			<div class="p-login-line"></div>
			<div class="p-login-input">
				<div class="p-login-span">密码</div><input @input="getcontent" v-model="password" type="password" class="p-login-input-text"></input>
			</div>
			<div class="p-login-line"></div>
			<div class="p-login-input">
				<div class="p-login-span">验证码</div><input @input="getcontent" v-model="code" type="text" class="p-login-input-code"></input>
				<span class="p-line-h"></span>
				<span class="p-getcode" @click="sendcode">{{value}}</span>
			</div>
			<div class="p-login-line"></div>
			<div class="p-login-other"><span @click="type=1">已注册，去登录 ＞</span></div>
			<div @click="login(3)" :class="['p-login-button',ispass==2?'p-login-button p-opcty':'']">确定</div>
			<div class="p-login-service">登录即同意<span @click="toxieyi('服务协议',3)">服务条款</span>和<span @click="toxieyi('隐私协议',item.id)">隐私政策</span></div>
		</div>
		<div class="p-loginbox" v-if="type==3">
			<div style="display: flex;flex-wrap: wrap;align-items: flex-start;">
				<!-- 	<div :class="['p-login-other-span',index==1?'p-login-other-span p-span-color':'']" @click="whichidnex(1,'QQ账号登录')"><img
					 class="p-logo-image" src="../../assets/img/login/qq.png" /><span>QQ账号登录</span></div> -->
				<div :class="['p-login-other-span',index==2?'p-login-other-span p-span-color':'']" @click="whichidnex(2,'微信账号登录')">
					<div :class="['p-logo-image-wc',index==2?'p-login-other-span  p-logo-image-wc-choose':'']" src="../../assets/img/login/wechatchoose.png" />
					<span>微信账号登录</span></div>
				<div :class="['p-login-other-span',index==3?'p-login-other-span p-span-color':'']" @click="whichidnex(3,'手机号登录')">
					<div :class="['p-logo-image-ph',index==2?'p-logo-image-ph p-logo-image-ph-choose':'']" />
					<span>手机号登录</span></div>
				<div class="loginfirst" @click="exitshow()">×</div>
			</div>
			<div v-if="index==3">
				<div class="p-login-which">手机号登录</div>
				<div class="p-login-input">
					<div class="p-login-span">手机号</div><input @input="getcontent" v-model="phone" type="number" class="p-login-input-text"></input>
				</div>
				<div class="p-login-line"></div>
				<div class="p-login-input">
					<div class="p-login-span">验证码</div><input @input="getcontent" v-model="code" type="text" class="p-login-input-code"></input>
					<span class="p-line-h"></span>
					<span class="p-getcode" @click="sendcode">{{value}}</span>
				</div>
				<div class="p-login-line"></div>
				<div @click="login(2)" :class="['p-login-button',ispass==2?'p-login-button p-opcty':'']">确定</div>
			</div>
			<div v-else>
				<div class="p-login-which-other">QQ和微信是两个独立的账号，账号信息不互通(暂没开放)</div>
				<div v-if="index==2">
					<div class="p-login-qcode">请使用微信扫描二维码，扫码登录。</div>
					<div id="login">
						<wxlogin class="p-login-qcode-img" appid="wxef4c6a2cd0d7ebb6" :scope="'snsapi_login'" :redirect_uri='urlpost'
						 :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgYm9yZGVyOiBub25lOwogIHdpZHRoOiAzNCU7CiAgaGVpZ2h0OiAzNCU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c3sKICBkaXNwbGF5OiBub25lOwp9'"
						 rel="external nofollow">
						</wxlogin>
					</div>
				</div>
				<!-- 	<div v-if="index==1">
					<div class="p-login-qcode fs20">快速安全登录</div>
					<div class="p-login-qcode">请使用QQ手机版扫描二维码，或点击头像授权登录。</div>
					<div class="p-qq-login-item">
						<div class="p-qq-logo">暂没支持</div>
					</div>
				</div> -->
			</div>
			<div class="p-login-bottom-list">
				<span @click="aboutlogin(2)" :class="['p-login-bottom-list-choose',whichlogin==2?'p-login-bottom-list-choose p-get-choose':'']">注册新账号</span>
				| <span @click="aboutlogin(1)" :class="['p-login-bottom-list-choose',whichlogin==1?'p-login-bottom-list-choose p-get-choose':'']">账号密码登录</span>
				| <span @click="aboutlogin(3)" :class="['p-login-bottom-list-choose',whichlogin==3?'p-login-bottom-list-choose p-get-choose':'']">意见反馈</span></div>
		</div>
		<div class="p-loginboxb" v-if="type==4">
			<div class="p-loginboxb-top"><span class="mr64">完善个人信息</span><span @click="exitshow()" class="loginfirst">×</span></div>
			<div style="width: 70%;margin-left: 15%;">
				<div class="c-title"><span class="c-mrspan9">基本信息</span></div>
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="头像">
						<el-upload action="123" :show-file-list="false" name="images" accept="image/*" :on-success="handleAvatarSuccess"
						 :before-upload="beforeAvatarUpload">
							<el-image v-if="userinfo.avatarurl" :preview-src-list="[userinfo.avatarurl]" :src="userinfo.avatarurl" class="p-imageavatar"></el-image>
							<i v-else class="el-icon-plus p-imageavatar"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="昵称">
						<el-input class="p-loginboxb-input" maxlength="10" v-model="userinfo.nickname"></el-input>
					</el-form-item>
					<div class="c-title"><span class="c-mrspan9">联系方式</span></div>
					<el-form-item label="手机号码">
						<el-input class="p-loginboxb-input" maxlength="11" v-model="userinfo.mobile"></el-input>
						<el-form-item>
							<img class="p-tips" src="../../assets/img/my/spantips.png" />用于接收平台和机构的学习提醒等服务，不对外公开
						</el-form-item>
					</el-form-item>
					<el-form-item label="常用邮箱">
						<el-input class="p-loginboxb-input" maxlength="30" v-model="userinfo.email"></el-input>
						<el-form-item>
							<img class="p-tips" src="../../assets/img/my/spantips.png" />用于接收平台和机构的邮件通知，务必认真填写
						</el-form-item>
					</el-form-item>
					<el-form-item label="QQ号码">
						<el-input class="p-loginboxb-input" maxlength="13" v-model="userinfo.qq"></el-input>
						<el-form-item>
							<img class="p-tips" src="../../assets/img/my/spantips.png" />用于课程的交流，便于学员与机构老师之间的沟通与交流
						</el-form-item>
					</el-form-item>
					<div class="savebutton" @click="edituser()">保存</div>
				</el-form>
			</div>
		</div>
		<div class="p-login-order" v-if="type==5">
			<div class="p-login-order-title">
				<img src="../../assets/img/login/sucess.png" />
				<div >报名成功</div>
				<div v-if="type==6">暂没开放</div>
			</div>
			<div class="p-login-content" v-if="type==6">
				恭喜你，报名成功，赶快去学习吧
			</div>
			<div class="p-login-conten-button" @click="tostudy()" >去学习</div>
			<div class="p-login-conten-button" @click="show=2" v-if="type==6">确定</div>
		</div>
		<div class="p-choose-like" v-if="type==6">
			<div class="p-choose-like-content">
				<div class="p-choose-head">
					<div>您对哪项有兴趣<span>至少选择一项，将于首页推荐，可随时调整</span></div><span class="the-nextchoose" @click="exitshow()">×</span>
				</div>
				<div class="p-itemall">
					<div class="p-choose-item" v-for="(item,index) in dataall" :key="index">
						<div class="p-choose-item-head">
							<span>{{item.cat_name}}</span>
						</div>
						<div class="p-choose-item-item">
							<div v-for="(item,indexa) in item.children" :key="indexa" @click="choosedata(item.id,index,indexa)" :class="['p-choose-item-item-div',item.ischoose==1?'p-choose-item-item-div p-choose-item-item-div-c':'']"><span>{{item.cat_name}}</span></div>
						</div>
					</div>
				</div>
				<div class="p-choose-bottom">
					<div></div>
					<div class="p-choose-b">
						<div class="p-choose-bottom-next" @click="exitshow()">下次再选</div>
						<div class="p-choose-bottom-submit" @click="choosedatasubmit()">选好了</div>
						<div class="p-choose-number">已选{{choosenumber}}项</div>
					</div>
				</div>
				<div class="p-choose-bottom">
				</div>
			</div>
		</div>
		<div v-if="type==7" class="advall">
			<img class="advimg" src="../../assets/img/adv/adv.png" />
			<img class="adveixt" src="../../assets/img/adv/eixt.png" @click="show=2" />
		</div>
	</div>
</template>


<script>
	import wxlogin from 'vue-wxlogin';
	export default {
		components: {
			wxlogin
		},
		data() {
			return {
				headimage: "",
				posts: "https://ykt.qdy2020.com/index/uploadimage",
				choosenumber: 0,
				userinfo: {
					avatarurl: "",
					nickname: "",
					mobile: "",
					email: "",
					qq: ""
				},
				timer: '',
				value: "获取验证码",
				dataall: [{
					title: "职场提升",
					children: [{
							name: "办公软件",
							id: 1,
							ischoose: 1,

						},
						{
							name: "办公软件2",
							id: 2,
							ischoose: 0,
						}
					]
				}, ],
				dataindex: [],
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				index: 3,
				phone: "",
				password: "",
				ispass: 1,
				code: "",
				token: "",
				tclick: 1,
				show: 0,
				whichlogin: 1, //1账号密码 2注册新账号 3 意见反馈
				type: 3, //1等于登录，2等于注册，3其他登录  4 完善资料 5 预定成功  6选择分类
				wallet: "",
				urlpost: '',
				timer: ""
			};
		},
		computed: {

		},
		watch: {
			'type': function() {

			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.value = "获取验证码"
		},
		created() {
			this.getgoodscate()

		},
		methods: {
			toshow(type) {
				this.show = 1
				this.type = type
				if(this.index==2){
					this.whichidnex(this.index,"微信账号登录")
				}if(this.index==3){
					this.whichidnex(this.index,"手机号登录")
				}
			},
			tostudy(e) {
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: this.type,
						isroom:2
					}
				})
			},
			whichidnex(e, name) {
				this.index = e
				clearInterval(this.timer);
				this.value = "获取验证码"
				this.tclick = 1
				if (e == 2) {
					this.getqcodeLogin()
				}
				localStorage.setItem("whichlogin", name)
			},
			aboutlogin(e) {
				this.whichlogin = e
				if(e!=3){
					clearInterval(this.timer);
					this.value = "获取验证码"
					this.tclick = 1
				}
				if (this.whichlogin == 1) {
					this.type = 1
				}
				if (this.whichlogin == 2) {
					this.type = 2
				}
			},
			//选择分类提交
			choosedatasubmit() {
				if (this.choosenumber > 7) {
					this.$message.error("兴趣每次只能选7个")
					return
				}
				this.$post('user/chooseinterest', {
					cate_ids: this.dataindex,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						this.show = 2
						this.$parent.getdata()
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			//选择兴趣分类
			choosedata(e, index, indexa) {
				for (var i = 0; i < this.dataindex.length; i++) {
					if (this.dataindex[i] == e) {
						this.dataall[index].children[indexa].ischoose = 0
						this.dataall[index].children.push(project)
						this.dataall[index].children.pop()
						this.dataindex[i] = ""
						if (this.dataindex[i] == "") {
							this.dataindex.splice(i, 1);
							i = i - 1;
						}
						this.choosenumber = this.dataindex.length
						return
					}
				}
				this.dataall[index].children[indexa].ischoose = 1
				this.dataindex.push(e)
				var project = {}
				this.dataall[index].children.push(project)
				this.dataall[index].children.pop()
				this.choosenumber = this.dataindex.length
			},
			//input事件
			getcontent() {
				if (this.type == 1) {
					if (this.phone && this.password) {
						this.ispass = 2
					}
					return
				}
				if (this.type == 2) {
					if (this.phone && this.password && this.code) {
						this.ispass = 2
					}
					return
				}
				if (this.type == 3) {
					if (this.phone && this.code) {
						this.ispass = 2
					}
					return
				}
			},
			//上传
			//头像上传
			handleAvatarSuccess(res, file) {},
			postpicture(file, which) {
				let param = new FormData(); // 创建form对象
				param.append('images', file); // 将文件存入file下面
				let config = {
					header: {
						'content-type': 'multipart/form-data'
					},
				}
				axios.post(this.posts, param, config)
					.then(res => {
						if (which == 1) {
							this.userinfo.avatarurl = res.data.data
						}
					}).catch(err => {
						console.log(err)

					})
			},
			beforeAvatarUpload(file) {
				var that = this
				this.postpicture(file, 1)
			},
			//登陆成功后获取用户信息
			getuser() {
				this.$get("user/getuserinfo", {}).then(response => {
					if (response.error == 0) {
						localStorage.setItem("name", response.data.userInfo.nickname)
						localStorage.setItem("img", response.data.userInfo.avatarurl)
						localStorage.setItem("is_auth", response.data.userInfo.is_auth)
						clearInterval(this.timer);
						this.index = 3
						this.$parent.setname(response.data.userInfo.nickname, response.data.userInfo.avatarurl, localStorage.getItem(
								"whichlogin"), response.data.userInfo.is_auth, response.data.userInfo.auth, response.data.userInfo.cart_count,
							response.data.userInfo.auth, response.data.userInfo.new_msg_count)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			IsEmail(str) {
				var reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
				return reg.test(str);
			},
			IsTelCode(str) {
				var reg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
				return reg.test(str);
			},
			//完善机构信息
			edituser() {
				this.getuser()
				if (!this.IsTelCode(this.userinfo.mobile)) {
					this.$message.success("请输入正确的手机号码")
					return
				}
				if (!this.IsEmail(this.userinfo.email)) {
					console.log(this.IsEmail(this.userinfo.email), "you")
					this.$message.success("请输入正确的邮箱号")
					return
				}
				this.$post("user/edituserinfo", {
					avatarurl: this.userinfo.avatarurl,
					nickname: this.userinfo.nickname,
					mobile: this.userinfo.mobile,
					email: this.userinfo.email,
					qq: this.userinfo.qq
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						this.getuser()
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			//登录三种状态写在一起，账号密码，手机，注册.
			login(e) {
				var that = this
				localStorage.setItem("whichlogin", "账号密码登录")
				if (e == 1) {
					var post = 'user/login'
					var data = {
						mobile: that.phone,
						password: that.password
					}
				}
				if (e == 2) {
					var post = 'user/loginbymobile'
					var data = {
						mobile: that.phone,
						code: that.code
					}
				}
				if (e == 3) {
					var post = 'user/registerbymobile'
					var data = {
						mobile: that.phone,
						password: that.password,
						code: that.code,
					}
				}
				this.$post(post, data).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						if (e == 3) {
							this.type = 1
							return
						}
						if (response.data.lastLoginTime == 0) {
							this.type = 4
							this.getuser()
							return
						}
						this.getuser()
						localStorage.setItem("token", response.data.token)
						this.show = 2
						//子组件使用父组件的办法
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			choosetype(e) {
				clearInterval(this.timer);
				this.whichidnex(3,'手机号登录')
				this.value = "获取验证码"
				this.tclick = 1
				this.type = e
			},
			onshow() {
				this.show = "2"
			},
			getqcodeLogin() {
				var num = Math.floor(Math.random() * 10000) + 1;
				this.urlpost = 'https://ykt.qdy2020.com/wechat/login/?id=' + num
				var that = this
				this.timer = setInterval(() => {
					that.checklogin(num);
				}, 3000);
			},
			async checklogin(id) {
				this.$get('wechat/read_status', {
					id: id
				}).then(response => {
					if (response.error == 0) {
						if (response.data) {
							localStorage.setItem("token", response.data)
							this.show = 2
							this.getuser()
						}
					} else {
						return
					}
				});

			},
			exitshow() {
				this.show = 0
				clearInterval(this.timer);
				this.tclick = 1
				this.value = "获取验证码"
			},
			//获取兴趣分类
			getgoodscate(data) {
				var that = this
				this.dataall = data
			},
			//发送二维码
			sendcode() {
				if (this.tclick == 1) {
					var that = this
					this.$get('code/sendverifycode', {
						phone: that.phone,
					}).then(response => {
						if (response.error == 0) {
							this.tclick = 2
							this.value = 60
							this.timer = setInterval(() => {
								this.value--
								if (this.value == 0) {
									this.tclick = 1
									this.value = "再次获取"
									clearInterval(that.timer);
								}
							}, 1000);
						} else {
							this.$message.error(response.msg)
							return
						}
					});
				}
			},
			// 服务条款
			toxieyi(name, id) {
				this.$router.push({
					path: '/xieyi',
					query: {
						name: name,
						id: id
					}
				})
			},
		},

	};
</script>

<style>
	.p-login-all {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 300;
		font-family: Source Han Sans CN !important;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.displayall {
		position: fixed;
		background: #000000;
		opacity: 0.5;
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.p-loginbox {
		position: relative;
		width: 724px;
		height: auto;
		background: #FFFFFF;
		z-index: 200;
	}

	.p-loginbox :nth-child(1) {
		text-align: center;
		width: 100%;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		font-size: 20px;
		line-height: 70px;

	}

	.loginfirst {
		width: 64px !important;
		height: 64px !important;
		background: #B3F1E7;
		float: right;
		font-size: 34px !important;
		text-align: center;
		cursor: pointer;
		line-height: 64px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
	}

	.p-login-input {
		width: 537px;
		background: #FFFFFF;
		margin: auto;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		display: flex;
		align-items: left;
		color: #333333;

	}

	.p-login-line {
		position: relative;
		width: 537px;
		height: 1px;
		background: #DDDDDD;
		margin: 0 auto;
		z-index: 300;
	}

	.p-login-span {
		width: 100px !important;

	}

	.p-login-input-text {
		width: 350px;
		height: 60px;
		margin-left: 51px;
		margin-top: 5px;
		font-size: 16px;
		background: #FFFFFF;
		border: #FFFFFF;
	}

	.p-login-input-code {
		width: 200px;
		height: 60px;
		margin-left: 51px;
		margin-top: 5px;
		background: #FFFFFF;
		border: #FFFFFF;
	}

	.p-line-h {
		width: 2px;
		height: 52px;
		margin-top: 13px;
		background: #DDDDDD;
	}

	.p-getcode {
		line-height: 70px;
		font-size: 16px;
		text-align: center;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-left: 31px;
		color: #00D0B0;
		cursor: pointer;
	}

	.p-login-button {
		width: 467px;
		height: 69px;
		background: #00D0B0;
		border-radius: 10px;
		margin: 0 auto;
		opacity: 0.5;
		cursor: pointer;
		line-height: 69px;
		text-align: center;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 25px;
		margin-bottom: 18px;
		color: #FFFFFF !important;

	}

	.p-opcty {
		opacity: 1 !important;
	}

	.p-login-other {
		text-align: right;
		width: 537px;
		margin: 0 auto;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		cursor: pointer;
		margin-top: 12px;

	}

	.p-login-other span {
		font-size: 16px !important;
		color: #00D0B0 !important;
	}

	.p-login-service {
		width: 100%;
		text-align: center;
		font-size: 16px;
		color: #999999;
	}

	.p-login-service span {
		font-size: 16px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		cursor: pointer;
		color: #00D0B0 !important;
	}

	.mr64 {
		margin-left: 64px;
	}

	/* 其他登录方式 */
	.p-login-other-span {
		width: 330px !important;
		height: 64px !important;
		background: #F9F9F9;
		font-size: 16px !important;
		display: flex;
		justify-content: center;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		cursor: pointer;
		color: #999999 !important;
	}

	.p-span-color {
		background: #00D0B0 !important;
		color: #FFFFFF !important;
	}

	.p-login-other-span img {
		width: 24px !important;
		height: 26px !important;
	}

	.p-login-which {
		text-align: center;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 80px;
	}

	.p-login-bottom-list {
		width: 98%;
		margin-bottom: 14px;
		text-align: right;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.p-login-bottom-list-choose {
		font-size: 12px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		height: 22px !important;
		color: #999999 !important;
		cursor: pointer;
	}

	.p-get-choose {
		border-bottom: 1px solid #00D0B0;
		color: #00D0B0 !important;
	}

	.p-login-which-other {
		text-align: center;
		font-size: 14px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999 !important;
		line-height: 80px;
	}

	.p-login-qcode {
		width: 180px !important;
		height: 38px;
		font-size: 16px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666 !important;
		line-height: 22px !important;
		margin: 0 auto;
		text-align: center;
	}

	.p-login-qcode-img {
		margin: 0 auto;
		width: 200px;
		height: 200px;
		margin-bottom: 120px;
	}

	.fs20 {
		font-size: 20px !important;
		color: #333333;
	}

	.wh203 {
		width: 206px !important;
		font-size: 16px !important;
		color: #666666 !important;
	}

	.p-qq-login-item {
		width: 100%;
		height: 86px;
		display: flex;
		justify-content: center;
		margin-top: 59px;
		margin-bottom: 20px;
	}

	.p-qq-logo {
		width: 86px !important;
		height: 86px !important;
		border-radius: 5px;
		margin-right: 38px;
	}

	.p-logo-image-wc {
		margin-top: 22px;
		width: 29px !important;
		margin-right: 9px;
		background: url(../../assets/img/login/wechat.png);
		background-size: 100% 100%;
		height: 24px !important;
	}

	.p-logo-image-wc-choose {
		background: url(../../assets/img/login/wechatchoose.png) !important;
		background-size: 100% 100% !important;
	}

	.p-logo-image-ph {
		margin-top: 22px;
		width: 18px !important;
		background: url(../../assets/img/login/phone.png);
		background-size: 100% 100%;
		height: 28px !important;
		margin-right: 13px;
	}

	.p-logo-image-ph-choose {
		background: url(../../assets/img/login/phoneunchoose.png) !important;
		background-size: 100% 100% !important;
	}

	/* 约定成功 */
	.p-login-order {
		width: 460px;
		height: 300px;
		background: #FFFFFF;
		position: relative;
		z-index: 300;
	}

	.p-login-content {
		width: 100%;
		height: 15px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		text-align: center;
		position: relative;
		top: 88px;
	}

	.p-login-conten-button {
		width: 355px;
		height: 68px;
		background: #00D0B0;
		border-radius: 10px;
		margin: 0 auto;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 68px;
		position: relative;
		top: 130px;
	}

	.p-login-order-title {
		position: relative;
		height: 35px;
		width: 100%;
		top: 63px;
		display: flex;
		justify-content: center;
	}

	.p-login-order-title img {
		height: 35px;
		width: 35px;
		margin-right: 15px;
	}

	.p-login-order-title div {
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	/* 完善资料 */
	.p-loginboxb {
		position: relative;
		width: 724px;
		height: auto;
		background: #FFFFFF;
		z-index: 300;
	}

	.p-loginboxb-top {
		text-align: center;
		width: 100%;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		font-size: 20px;
		line-height: 70px;
	}

	.p-loginboxb-input {
		width: 198px;
		height: 38px;
		border-radius: 8px;
	}

	.p-tips {
		width: 13px;
		height: 14px;
		margin-right: 4px;
		margin-top: 9px;
		float: left;
		margin-bottom: auto;
	}

	.p-text {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-left: 14px;
		margin-bottom: 13px;
		color: #666666;
	}

	.p-imageavatar {
		height: 100px !important;
		width: 100px !important;
	}

	.savebutton {
		width: 146px;
		height: 54px;
		background: #00D0B0;
		line-height: 54px;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		text-align: center;
		color: #FFFFFF;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	/* 选择兴趣标签 */
	.p-choose-like {
		width: 80%;
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		position: relative;
		z-index: 300;
		cursor: pointer;
	}

	.p-choose-like-content {
		height: auto;
		width: 92%;
		margin-left: 4%;
	}

	.p-choose-head {
		height: 120px;
		line-height: 120px;
		display: flex;
		font-size: 32px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		justify-content: space-between;
	}

	.p-choose-head span {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-left: 16px;
	}

	.p-choose-head img {
		width: 24px;
		height: 24px;
		margin-bottom: auto;
		margin-top: auto;
	}

	.p-itemall {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-choose-item {
		width: 50%;
		height: auto;
		margin-bottom: 30px;
	}

	.p-choose-item-head {
		width: 100%;
		border-left: 4px solid #00D0B0;
		height: 14px;
		line-height: 14px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.p-choose-item-head span {
		margin-left: 9px;
	}

	.p-choose-item-item {
		width: 100%;
		height: auto;
		display: flex !important;
		flex-wrap: wrap !important;
		align-items: flex-start !important;
	}

	.p-choose-item-item-div {
		height: 34px;
		border: 1px solid #CCCCCC;
		border-radius: 17px;
		margin-top: 15px;
		line-height: 34px;
		font-size: 14px;
		margin-right: 16px;
	}

	.p-choose-item-item-div-c {
		color: #FFFFFF;
		background: #00D0B0;
		border: none;
	}

	.p-choose-item-item span {
		margin-left: 15px;
		margin-right: 15px;
	}

	.p-choose-bottom {
		height: 54px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.p-choose-b {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.p-choose-bottom-next {
		width: 146px;
		height: 54px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		font-size: 20px;
		text-align: center;
		line-height: 54px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;

	}

	.p-choose-bottom-submit {
		width: 146px;
		height: 54px;
		background: #00D0B0;
		font-size: 20px;
		margin-left: 40px;
		text-align: center;
		line-height: 54px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;

	}

	.p-choose-number {
		line-height: 54px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-left: 16px;
	}

	/* 自定义的二维码样式 */
	.impowerBox .title {
		display: none;
	}

	.impowerBox .status.status_browser {
		display: none;
	}

	.impowerBox .qrcode {
		border: none;
		width: 150px;
		height: 150px;
	}

	.impowerBox .status {
		display: none
	}

	/* 广告图片 */
	.advall {
		z-index: 300;
		display: flex;
		flex-wrap: wrap;
		width: 604px;
		align-items: center;
		justify-content: center;
	}

	.advimg {
		width: 604px;
		height: 577px;
	}

	.adveixt {
		height: 65px;
		width: 65px;
		margin-top: 177px;
	}

	.the-nextchoose {
		font-size: 36px !important;
	}
</style>
